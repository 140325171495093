/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./NodeRecord.vue?vue&type=template&id=2defc246&scoped=true"
import script from "./NodeRecord.vue?vue&type=script&lang=js"
export * from "./NodeRecord.vue?vue&type=script&lang=js"
import style0 from "./NodeRecord.vue?vue&type=style&index=0&id=2defc246&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2defc246",
  null
  
)

export default component.exports