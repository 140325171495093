var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('div', {
    class: {
      'over-view-button': true,
      'over-view-button-enable': _vm.userInfo != null
    },
    on: {
      "click": _vm.showChangeRecordDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Overview')) + " ")]), _c('pop-dialog', {
    attrs: {
      "show": _vm.showRecordDialog,
      "height": "auto",
      "is-close": false,
      "width": _vm.isMobile ? '90vw' : '480px'
    },
    on: {
      "closeFn": function ($event) {
        return _vm.changeRecordDialog(false);
      }
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.$lang("Data Overview")))]), _c('div', {
    staticClass: "over-view-header"
  }, [_c('div', {
    staticClass: "over-view-header-left"
  }, [_vm._v(_vm._s(_vm.$lang('Content')))]), _c('div', {
    staticClass: "over-view-header-center"
  }, [_vm._v(_vm._s(_vm.$lang('Quantity')))]), _c('div', {
    staticClass: "over-view-header-right"
  }, [_vm._v(_vm._s(_vm.$lang('Value(USDT)')))])]), _c('div', {
    staticClass: "over-view-content"
  }, [_c('div', {
    staticClass: "over-view-line-item"
  }, [_c('div', {
    staticClass: "over-view-line-item-left"
  }, [_vm._v(_vm._s(_vm.$lang('Promotion Node')))]), _c('div', {
    staticClass: "over-view-line-item-center"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_nodes || 0)))]), _c('div', {
    staticClass: "over-view-line-item-right"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_nodes_hashrate || 0)))])]), _c('div', {
    staticClass: "over-view-line-item"
  }, [_c('div', {
    staticClass: "over-view-line-item-left"
  }, [_vm._v(_vm._s(_vm.$lang('Promotion Active')))]), _c('div', {
    staticClass: "over-view-line-item-center"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_active_amount || 0)))]), _c('div', {
    staticClass: "over-view-line-item-right"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_active_hashrate || 0)))])]), _c('div', {
    staticClass: "over-view-line-item"
  }, [_c('div', {
    staticClass: "over-view-line-item-left"
  }, [_vm._v(_vm._s(_vm.$lang('Promotion Fixed')))]), _c('div', {
    staticClass: "over-view-line-item-center"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_fixed_amount || 0)))]), _c('div', {
    staticClass: "over-view-line-item-right"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_fixed_hashrate || 0)))])]), _c('div', {
    staticClass: "over-view-line-item"
  }, [_c('div', {
    staticClass: "over-view-line-item-left"
  }, [_vm._v(_vm._s(_vm.$lang('Promotion Burn')))]), _c('div', {
    staticClass: "over-view-line-item-center"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_fixed_amount || 0)))]), _c('div', {
    staticClass: "over-view-line-item-right"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.overviewData.total_burn_hashrate || 0)))])])]), _c('div', {
    staticClass: "close-btn",
    on: {
      "click": function ($event) {
        return _vm.changeRecordDialog(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Close')))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };