import Vue from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import store from "./store";
import "./assets/css/reset.scss";
import "./assets/css/loading.scss";
import "./assets/icons"; //svg图标
import "./assets/js/rem";
import "./assets/fonts/font.css";
import Axios from "./http/index";
import router from "./router";
import formatter from "./utils/formatter";
import directive from "./directive";
import Toasted from "vue-toasted";
// import VConsole from 'vconsole';
// const vConsole = new VConsole();
const options = {
  duration: 3000,
  position: "top-right",
  fullWidth: false,
};
Vue.use(Toasted, options);
Vue.use(directive);

// import myLoading from "./components/myLoading";
// Vue.use(myLoading);

for (let func in formatter) {
  Vue.filter(func, formatter[func]);
}

Vue.prototype.remTran = (val) => Number(val) / 188 + "rem";
Vue.prototype.theme = (color) => getComputedStyle(document.body).getPropertyValue(color).trim();
Vue.prototype.formatter = formatter;

Vue.config.productionTip = false;

Vue.prototype.registerBodyClick = (isRegister, callback) => {
  isRegister ? document.body.addEventListener("click", callback) : document.body.removeEventListener("click", callback);
};

Axios.install(Vue);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
