var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "node-record-button",
    on: {
      "click": _vm.showDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Record')) + " ")]), _c('pop-dialog', {
    attrs: {
      "show": _vm.showRecordDialog,
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '500px'
    },
    on: {
      "closeFn": function ($event) {
        return _vm.changeRecordDialog(false);
      }
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.$lang("Node Record")))]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    class: _vm.isMobile && 'wap',
    on: {
      "scroll": _vm.checkScroll
    }
  }, [_vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "log-item-title"
    }, [_vm._v("No." + _vm._s(index + 1))]), _c('div', {
      staticClass: "log-item-coin"
    }, [_vm._v(" " + _vm._s(item.price) + "$ USDT ")]), _c('div', {
      staticClass: "log-item-date"
    }, [_vm._v(" " + _vm._s(item.receive_time_format) + " ")])]);
  }), _vm.logLoading ? _c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)]) : _vm._e()], 2)]), _c('div', {
    staticClass: "close-btn",
    on: {
      "click": function ($event) {
        return _vm.changeRecordDialog(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Close')))])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };