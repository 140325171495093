var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-stake",
    class: _vm.isMobile && 'content-stake-wap'
  }, [_c('div', {
    staticClass: "line-one"
  }, [_c('div', {
    staticClass: "line-one-title"
  }, [_vm._v("Stake")]), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "line-one-desc",
    on: {
      "click": function ($event) {
        _vm.popTipShow = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Rules')))]), _c('StakeRecord')], 1)]), _c('div', {
    staticClass: "split-line"
  }), _c('pop-prompt-two', {
    attrs: {
      "show": _vm.popTipShow,
      "title": _vm.$lang('Staking Rules'),
      "descs": [_vm.$lang('Flexible Staking: 1x hash rate, T+1 token generation, withdrawal fee is 5% of the staked amount.'), _vm.$lang('Fixed Staking (60 days): 2x hash rate, T+0 token generation, withdrawal fee is 1% of the staked amount.'), _vm.$lang('Burning: 4x hash rate, T+0 token generation.')]
    },
    on: {
      "update:show": function ($event) {
        _vm.popTipShow = $event;
      }
    }
  }), _c('div', {
    staticClass: "stake-bottom-content"
  }, [_c('div', {
    staticClass: "line-two"
  }, [_c('div', {
    staticClass: "input-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    attrs: {
      "type": "number",
      "placeholder": _vm.$lang('Minimum 100')
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "keyup": function ($event) {
        _vm.inputValue = parseInt(_vm.inputValue);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inputValue = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "split-char"
  }, [_vm._v("=")]), _c('div', {
    staticClass: "value-1"
  }, [_vm._v(" " + _vm._s(_vm.calcResult) + " ")]), _c('div', {
    staticClass: "value-1"
  }, [_vm._v(" ⚡ ")])]), _c('div', {
    staticClass: "line-three"
  }, [_c('div', [_vm._v(_vm._s(_vm.$lang('Balance')))]), _c('div', [_vm._v(_vm._s(_vm.rectCoinBalance) + " RECT")])]), _c('div', {
    staticClass: "line-four"
  }, [_c('div', {
    staticClass: "button-common",
    class: _vm.stakeType == 2 ? 'button-common-selected' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Burn')) + "*4 ")]), _c('div', {
    staticClass: "button-common",
    class: _vm.stakeType == 1 ? 'button-common-selected' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Fixed')) + "*2 ")]), _c('div', {
    staticClass: "button-common",
    class: _vm.stakeType == 0 ? 'button-common-selected' : '',
    on: {
      "click": function ($event) {
        return _vm.switchStakeType(0);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Active')) + "*1 ")])]), _c('div', {
    staticClass: "content-button-wrapper"
  }, [_c('div', {
    staticClass: "content-button"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require('@images/threeStar.png'),
      "alt": ""
    }
  })]), _vm.isApprove || !_vm.inputValue ? [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.doStake
    }
  }, [_vm._v(_vm._s(_vm.$lang('Stake')))])] : [_c('div', {
    staticClass: "title",
    on: {
      "click": function ($event) {
        return _vm.doApprove();
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Approve')))])], _c('div', {
    staticClass: "right-icon"
  }, [_vm.isLoading ? [_c('svg-icon', {
    staticStyle: {
      "scale": "2"
    },
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : [_c('svg', {
    staticStyle: {
      "transform": "scale(1)"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "8",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M1 3.5a.5.5 0 0 0 0 1zm7.354.854a.5.5 0 0 0 0-.708L5.172.464a.5.5 0 1 0-.708.708L7.293 4 4.464 6.828a.5.5 0 1 0 .708.708zM1 4.5h7v-1H1z"
    }
  })])]], 2)], 2)])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };