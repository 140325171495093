import Vue from "vue";
import vailcode from "@utils/errcode";

import web3Wallet from "@web3Wallet/index";
import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
export default {
    namespaced: true,
    state: {
        nftSellInfo:{},
        isLoading: false,
        isApprove:true,
    },
    mutations: {
        setNftSellInfo: (state, payload) => {
            state.nftSellInfo = payload;
        },
        setIsLoading: (state, payload) => {
            state.isLoading = payload
        },
        setApprove:(state, payload) => {
            state.isApprove  = payload
        },
    },
    actions: {
        getNftBaseInfo:async ({ commit, dispatch }) => {
            return new Promise(async (resolve, reject) => {
                try { 
                    let addr =  web3Wallet.address ? web3Wallet.address: "0x0000000000000000000000000000000000000000"
                    const res = await web3Wallet.contract('mint').call("getBaseInfo",[addr == "0x0000000000000000000000000000000000000000"  ?  "0x0000000000000000000000000000000000000001"  : addr])
                    const usdtBalance = addr == "0x0000000000000000000000000000000000000000" ? 0 : await web3Wallet.contract('token',web3Config.usdt_address).call("balanceOf",[addr])
                    const baseInfo = {
                        userMint:res._balance,
                        mintLimit:res._mintLimit,
                        mintTotal:res._mintTotal,
                        openTotalMint:res._openTotalMint,
                        nowTime:res._nowTime,
                        startTime:res._startTime,
                        startPrice:res._startPrice,
                        startPrice_label:new BigNumber(res._startPrice).div(10**web3Config.usdt_decimals).toFixed(2),
                        totalSupply:res._totalSupply,
                        progress:(res._mintTotal*100 / res._totalSupply).toFixed(0),
                        usdtBalance
                    }
           
                    commit("setNftSellInfo",baseInfo)
                    resolve()
                } catch (err) {
                    console.log("getNftBaseInfo===", err);
                    resolve()
                }
           });
        },
        calcPrice:async ({ commit, dispatch },[num,callback]) => {
            try{
                commit("setIsLoading", true);
                const  res =  await web3Wallet.contract('mint').call("calcPrice",[num])
                commit("setIsLoading", false);
                console.log("calcPrice===",res)
                typeof(callback) == 'function' && callback(res)
            }
            catch (err) {
                console.log("calcPrice===", err);
                commit("setIsLoading", false);
            }
        },
        checkApprove:async ({ state, commit, rootState },price) => {
            const params = [web3Wallet.address, web3Config.mint_contract];
            console.log("params==",web3Config.usdt_address, params);
            commit("setIsLoading", true);
            try{
              const res = await web3Wallet.contract("token",web3Config.usdt_address).call("allowance", params);
              commit("setIsLoading", false);
              console.log("checkApprove===",res);
              if(new BigNumber(res).gte(price)){
                commit("setApprove",true);
              }else{
                commit("setApprove",false);
              }
            }
            catch (err) {
                commit("setIsLoading", false);
            }
          },
          doApprove:async ({ state, commit, rootState,dispatch }, [price,callback]) => {
            commit("setIsLoading", true);
            try{
              const params = [web3Config.mint_contract,price];
              console.log("params==",web3Config.usdt_address, params);
              const res = await web3Wallet.contract("token",web3Config.usdt_address).send("approve", params);
              dispatch('checkApprove',price);
              commit("setIsLoading", false);
              typeof callback == 'function' && callback()
            }
            catch (err) {
              commit("setIsLoading", false);
            }
          },
          doBuy : async ({ state, commit, rootState,dispatch }, [price,buyNum,invite,callback]) => {
            commit("setIsLoading", true);
            try{
                if(!buyNum){
                    commit("setIsLoading", false);
                    Vue.prototype.$toasted.error('Purchase Number Error!')
                    return;
                }
                
                let rs =await Vue.prototype.$http.post("buy/node",{
                    sender:web3Wallet.address,
                    receiver:web3Wallet.address
                });

                vailcode(rs,async ()=>{
                    const params = [rs.data.receiver,buyNum,rs.data.timestamp,rs.data.firstInviter,rs.data.lastInviter,rs.data.seeds];
                    console.log("params==",params);
                    try{
                        const res = await web3Wallet.contract("mint").send("mint", params);
                        dispatch('getNftBaseInfo');
                        commit("setIsLoading", false);
                        dispatch('checkApprove',price);
                        typeof callback == 'function' && callback()
                    }
                    catch(eee){
                        commit("setIsLoading", false);
                    }
                },()=>{
                    commit("setIsLoading", false);
                })
               
              
            }
            catch (err) {
            commit("setIsLoading", false);
            }
          }
        
    },
    getters: {

    }
}