import BigNumber from "bignumber.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address", "userInfo"])
  },
  data() {
    return {
      showRecordDialog: false,
      overviewData: {}
    };
  },
  methods: {
    async showChangeRecordDialog() {
      if (this.userInfo == null) {
        return;
      }
      this.showRecordDialog = true;
      let rs = await this.$http.post("/stake/overview", {
        owner: this.address
      });
      //let rs = await this.$http.post("/stake/overview",{owner:'0xC0EFc14c9a8e228624896a4A5C44E3CB5553922a'})
      if (rs.code == 0) {
        let overviewData = rs.data;
        overviewData.total_nodes_hashrate = Number(overviewData.total_nodes_hashrate).toFixed(2);
        overviewData.total_active_hashrate = Number(overviewData.total_active_hashrate).toFixed(2);
        overviewData.total_active_amount = BigNumber(overviewData.total_active_amount).div(10 ** 18).toFixed(2);
        overviewData.total_fixed_hashrate = Number(overviewData.total_fixed_hashrate).toFixed(2);
        overviewData.total_fixed_amount = BigNumber(overviewData.total_fixed_amount).div(10 ** 18).toFixed(2);
        overviewData.total_burn_hashrate = Number(overviewData.total_burn_hashrate).toFixed(2);
        overviewData.total_burn_amount = BigNumber(overviewData.total_burn_amount).div(10 ** 18).toFixed(2);
        this.overviewData = overviewData;
      }
    },
    changeRecordDialog() {
      this.showRecordDialog = false;
    }
  }
};